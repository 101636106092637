.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white !important;
    z-index: 1;
}

.dimenisune-col {
    width: 150px;
    min-width: 150px;
    max-width: 300px;
    font-size: 14px;
    position: relative;
}

.dimenisune-col3 {
    width: 450px;
    min-width: 450px;
    max-width: 900px;
    font-size: 14px;
}

.dimenisune-col2 {
    width: 50px;
    min-width: 50px;
    max-width: 300px;
    font-size: 14px;
}

.first-col {
    width: 150px;
    min-width: 150px;
    max-width: 300px;
    left: 0px;
    font-size: 14px;
}

.second-col {
    width: 150px;
    min-width: 150px;
    max-width: 300px;
    left: 150px;
    font-size: 14px;
}

.third-col {
    width: 150px;
    min-width: 150px;
    max-width: 300px;
    left: 300px;
    font-size: 14px;
}
